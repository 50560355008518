const axios = require('axios');
module.exports = class Login {
	constructor (object) {
		this.username = object.username;
		this.password = object.password;
		this.sessionType = 'WEB';
		this.ip = '127.0.0.1';
	}

	async getPublicIpAndPort () {
		try {
			const response = await axios.get('https://httpbin.org/ip');

			const publicIp = response.data.origin;
			// const publicPort = response.data.headers.Host.split(':')[1];
			return publicIp.toString();
		} catch (error) {
			return '127.0.0.1';
		}
	}
};
